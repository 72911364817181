<template>
	<v-container fluid>
		<div>
			<AButton
				color="blue lighten-4"
				class="mt-2"
				:block="true"
				title="기본 알림 팝업"
				@click="handlePop0"
			/>
			<AButton
				color="blue lighten-4"
				class="mt-2"
				:block="true"
				title="기본 알림 팝업 - 버튼명 변경"
				@click="handlePop1"
			/>
			<AButton
				color="blue lighten-4"
				class="mt-2"
				:block="true"
				title="발송/취소 팝업"
				@click="handlePop2"
			/>
			<AButton
				color="blue lighten-4"
				class="mt-2"
				:block="true"
				title="신규/기존고객 정보 팝업"
				@click="handleSmsDialog"
			/>
			<AButton
				color="blue lighten-4"
				class="mt-2"
				:block="true"
				title="수납 즉시이체 팝업"
				@click="handleRequestInstantTransferDialog"
			/>
			<AButton
				color="blue lighten-4"
				class="mt-2"
				:block="true"
				title="수납 카드이체 팝업"
				@click="handleRequestCardTransferDialog"
			/>
			<AButton
				color="blue lighten-4"
				class="mt-2 pop_system"
				:block="true"
				title="시스템 팝업-제목없음"
				@click="handlePop3"
			/>
			<AButton
				color="blue lighten-4"
				class="mt-2 pop_system"
				:block="true"
				title="시스템 팝업-제목있음"
				@click="handlePop4"
			/>
			<AButton
				color="blue lighten-4"
				class="mt-2 pop_system"
				:block="true"
				title="스택바"
				@click="snack"
			/>
			<MSelectBottom
				v-model="telCompany"
				title="통신사 선택"
				:items="telCompanyItems"
				@item-selected="handleTelCompanySelected"
				><template
					>{{ telCompanyDisplayText }}<span class="btn_value"></span></template
			></MSelectBottom>
			<MSelectBottom
				v-model="stand"
				title="스탠드 추가 구매"
				:items="standItems"
				@item-selected="handleStandSelected"
				><template
					>{{ standDisplayText }}<span class="btn_value"></span></template
			></MSelectBottom>
			<MSelectBottom
				v-model="monthInstallment"
				title="할부개월"
				:items="monthItems"
				@item-selected="handleMonthInstallmentSelected"
				><template
					>{{ monthInstallmentDisplayText
					}}<span class="btn_value"></span></template
			></MSelectBottom>
			<RentalFeeFilterBottom
				text="렌탈료/일시불"
				title="렌탈료/일시불"
				:items="list"
				@item-selected="handleRentalFilter"
			/>
			<!-- <MSelectMultiBottom
				text="타입"
				title="타입"
				:items="typeItems"
				:all="true"
				@item-selected="handleItemSelected"
			/> -->

			<VerifySmsDialog ref="sms" />
			<CustomRecpDialog ref="recpPop" />
		</div>
	</v-container>
</template>
<script>
// import RecpDialog from '@/components/biz/dialog/RecpDialog'
// import Sms from '@/components/biz/dialog/VerifySmsDialog'
// import MSelectBottom from '@/components/molecules/MSelectBottom'
// import MSelectMultiBottom from '@/components/molecules/MSelectMultiBottom'
import { mapActions } from 'vuex'
export default {
	components: {},
	computed: {
		filteredProducts() {
			return this.list.filter(x => x)
		}
	},
	mounted() {},
	data() {
		return {
			telCompanyItems: [
				{ code: 'SK', text: 'SKT' },
				{ code: 'KT', text: 'KT' },
				{ code: 'LGU+', text: 'LGU+' },
				{ code: '알뜰폰', text: '알뜰폰' }
			],
			standItems: [
				{ code: 'SL', text: '스탠드 L (높이 15cm)' },
				{ code: 'SS', text: '스탠드 S (높이 8cm)' }
			],
			typeItems: [
				{ code: '00', text: '전체' },
				{ code: '01', text: '마이 한뼘' },
				{ code: '02', text: '한뼘' }
			],
			SaleItems: [
				{ code: '0019', text: 'NCP' },
				{ code: '0018', text: 'CRP' },
				{ code: '0001', text: '일반' }
			],
			monthItems: [
				{ code: '3', text: '3개월' },
				{ code: '6', text: '6개월' },
				{ code: '12', text: '12개월' },
				{ code: '12', text: '12개월' },
				{ code: '12', text: '12개월' },
				{ code: '12', text: '12개월' },
				{ code: '12', text: '12개월' },
				{ code: '12', text: '12개월' },
				{ code: '12', text: '12개월' },
				{ code: '12', text: '12개월' },
				{ code: '12', text: '12개월' },
				{ code: '12', text: '12개월' },
				{ code: '12', text: '12개월' },
				{ code: '12', text: '12개월' },
				{ code: '12', text: '12개월' },
				{ code: '12', text: '12개월' },
				{ code: '12', text: '12개월' },
				{ code: '12', text: '12개월' },
				{ code: '12', text: '12개월' },
				{ code: '36', text: '36개월' }
			],
			list: [],
			filters: {},
			telCompany: '',
			telCompanyDisplayText: '통신사 선택',
			stand: '',
			standDisplayText: '프리스탠드',
			monthInstallment: '',
			monthInstallmentDisplayText: '할부개월 선택'
		}
	},
	methods: {
		...mapActions({ showSnackbar: 'common/showSnackbar' }),
		...mapActions({ setToken: 'common/setToken' }),
		handleItemSelected(item) {
			this.$log('selected item:', item)
		},
		handleTelCompanySelected(item) {
			this.$log('selected item:', item)
			this.telCompanyDisplayText = item.text
		},
		handleStandSelected(item) {
			this.$log('selected stand item:', item)
			this.standDisplayText = item.text
		},
		handleMonthInstallmentSelected(item) {
			this.monthInstallmentDisplayText = item.text
		},
		handleRentalFilter(item) {
			this.$log('selected rental filter:', item)
			let filters = {}
			const { rentalPrice, lumSumPrice } = item
			if (rentalPrice.index !== undefined) {
				switch (rentalPrice.index) {
					case 0:
						filters['month_amt'] = monthAmt => monthAmt < 20000
						break
					case 1:
						filters['month_amt'] = monthAmt =>
							monthAmt >= 20000 && monthAmt < 30000
						break
					case 2:
						filters['month_amt'] = monthAmt =>
							monthAmt >= 30000 && monthAmt < 40000
						break
					case 3:
						filters['month_amt'] = monthAmt =>
							monthAmt >= 40000 && monthAmt < 50000
						break
					case 4:
						filters['month_amt'] = monthAmt =>
							monthAmt >= 50000 && monthAmt < 60000
						break
					case 5:
						filters['month_amt'] = monthAmt => monthAmt >= 60000
						break
				}
				// filters['reg_price'] = monthAmt => monthAmt < 20000
			}

			this.$log(lumSumPrice)
			if (lumSumPrice.min > 0 && lumSumPrice.max > 0) {
				this.$log('a')
				filters['price'] = price =>
					price >= lumSumPrice.min && price < lumSumPrice.max
			} else if (lumSumPrice.max > 0) {
				this.$log('b')
				filters['price'] = price => lumSumPrice.max >= price
			} else if (lumSumPrice.min > 0) {
				this.$log('c')
				filters['price'] = price => lumSumPrice.min <= price
			}
			// const filters = {
			// 	size: size => size === 50 || size === 70,
			// 	color: color => ['blue', 'black'].includes(color.toLowerCase()),
			// 	details: details => details.length < 30 && details.width >= 70,
			// 	locations: locations => {
			// 		if (locations.includes('USA')) return true
			// 		return false
			// 	}
			// }

			const filtered = this.filterArray(this.list, filters)
			this.$log(filtered.length, filtered)
			// const filterCallback = (code, value) => {
			// 	return (
			// 		code.Codice.toLowerCase().includes(value.toLowerCase()) ||
			// 		code.Descrizione.toLowerCase().includes(value.toLowerCase())
			// 	)
			// }
			// const codesToShow = codes.filter(code => filterCallback(code, value))
		},
		filterArray(array, filters) {
			const filterKeys = Object.keys(filters)
			return array.filter(item => {
				// validates all filter criteria
				return filterKeys.every(key => {
					// ignores non-function predicates
					if (typeof filters[key] !== 'function') return true
					return filters[key](item[key])
				})
			})
		},
		async handleSmsDialog() {
			const cust = {
				name: '주현',
				sex: '1',
				bahns: '20000101',
				mob: '0101234567',
				existing: false
			}
			const res = await this.$refs.sms.open(cust)
			this.$log('res:', res)
			if (res) this.$log('발송처리')
		},
		async handleRequestInstantTransferDialog() {
			const options = {
				title: '다음 계좌를 통해 즉시이체를 요청합니다.',
				guide: '즉시 이체는 계약서 서명 후 진행됩니다.',
				contents: {
					예금주: '주현',
					생년월일: '850927',
					납부방법: '우리은행***',
					납부금액: '5,000,000원'
				},
				okText: '즉시이체',
				cancelText: '수납방법 재선택'
			}
			const res = await this.$refs.recpPop.open(options)
			this.$log('res:', res)
			if (res) this.$log('발송처리')
		},
		async handleRequestCardTransferDialog() {
			const options = {
				title: '다음 카드를 통해 결제를 요청합니다.',
				// guide: '즉시 이체는 계약서 서명 후 진행됩니다.',
				contents: {
					'수납 방법': '신한카드 4499',
					'수납 금액': '5,000,000원'
				},
				okText: '결제',
				cancelText: '수납방법 재선택'
			}
			const res = await this.$refs.recpPop.open(options)
			this.$log('res:', res)
			if (res) {
				this.$log('수납완료 팝업 띄움')
				this.$alert({
					message: '수납이 완료됐습니다.<br/>설치정보로 이동합니다',
					okText: '설치정보로 이동'
				})
			} else {
				this.$log('수납거부 사유 팝업 띄움')
				const optionsReject = {
					title: '다음 카드를 통해 결제를 요청합니다.',
					// guide: '즉시 이체는 계약서 서명 후 진행됩니다.',
					contents: {
						'거부 사유': '결제한도 부족',
						'수납 금액': '5,000,000원'
					},
					okText: '임시저장',
					cancelText: '수납방법 재선택'
				}
				const res1 = await this.$refs.recpPop.open(optionsReject)
				if (res1) {
					this.$log(res1)
				}
			}
		},

		handlePop0() {
			this.$alert({ message: '주문가능 수량 부족으로 추가 할 수 없습니다.' })
		},
		async handlePop1() {
			const res = await this.$alert({
				message:
					'인증번호 입력시간이 지났습니다.<br/>인증 메시지를 다시 보내시겠습니까?',
				okText: '재전송'
			})
			this.$log('res:', res)
		},
		async handlePop2() {
			const res = await this.$alert({
				message:
					'인증코드 발급이 불가능한 고객입니다. 고객님에게 인증코드 발급 메시지를 발송하시겠습니까?',
				okText: '발송',
				cancelText: '취소'
			})
			this.$log('res:', res)
		},
		async handlePop3() {
			const res = await this.$alert({
				message: '전체 상품을 삭제하고 상품선택 화면으로 이동합니다.',
				okText: '확인',
				cancelText: '취소'
			})
			this.$log('res:', res)
		},
		async handlePop4() {
			const res = await this.$alert({
				title:
					'아래와 같이 상품 및 판매유형이 설정되어 수량을 변경할 수 없습니다.',
				message: '전체 상품을 삭제하고 상품선택 화면으로 이동합니다.',
				okText: '확인',
				cancelText: '취소'
			})
			this.$log('res:', res)
		},
		snack() {
			this.showSnackbar({ msg: '스낵바', color: '#222' })
			// this.setToken('eee')
		}
	}
}
</script>
